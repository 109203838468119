<!-- eslint-disable vue/multi-word-component-names -->
<script setup>

</script>

<template>
  <div class="skeleton">
    <div
      v-for="i in 10"
      :key="i"
      class="skeleton__item"
    >
      <div class="skeleton__img"></div>
      <div class="skeleton__name"></div>
      <div class="skeleton__price"></div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.skeleton {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  padding: 0 28px 0 48px;
  width: 100%;
  height: 609px;
  &__item {
    margin: 0 20px 32px 0;
    width: calc(20% - 20px);
  }
  &__img {
    width: 100%;
    height: 206px;
    background: @sui_color_gray_weak1;
  }
  &__name {
    margin-top: 10px;
    width: 80%;
    height: 16px;
    background: #F2F2F2;
  }
  &__price {
    margin-top: 4px;
    width: 30%;
    height: 16px;
    background: #F2F2F2;
  }
}
</style>
