<template>
  <s-dialog
    v-model:visible="isShow"
    class="cart-promotion__drawer"
    :show-close="false"
    type="W996"
    @closed="handleClosed"
  >
    <ModalHeader
      :title="language.SHEIN_KEY_PC_28540"
      :count-down-time-stamp="promotionItem.is_count_down == 1 ? promotionItem.end_time : 0"
      @close="handleClose"
    />
    <div class="cart-promotion__tab-alert-wrapper">
      <GearTab
        :model-value="activeIndex"
        :tabs="tabs"
        :type-id="typeId"
        :fulfill-status="fulfillStatus"
        :promotion-id="promotionId"
        :language="language"
      />
      <AlertTips
        :text="promotionAlertTips"
        :promotion-type-id="typeId"
        :show-add-btn="buyGiftsDisabled"
        :promotion-id="promotionId"
        :level="0"
      />
    </div>
    <ul
      v-show="!firstLoad"
      ref="productList"
      v-infinite-scroll="handleInfiniteScroll"
      class="promotion-product__list"
      infinite-scroll-disabled="noMoreData"
      infinite-scroll-nodata="false"
      infinite-scroll-distance="10"
    >
      <PromotionProductItem
        v-for="(item, index) in gifts"
        :key="'promotion-list-item-' + index + '-' + item.goods_id"
        :item="item"
        :promotion-item="promotionItem"
        :is-disabled="buyGiftsDisabled"
        :fulfill-status="fulfillStatus"
        :index="index"
        :disabled-tip="promotionAlertTips"
        :page="page"
      />
    </ul>
    <Skeleton v-show="firstLoad" />
  </s-dialog>
</template>

<script>
import { mapState, useStore } from 'vuex'
import ModalHeader from './components/Header.vue'
import GearTab from './components/GearTab.vue'
import AlertTips from './components/AlertTips.vue'
import PromotionProductItem from './components/PromotionProductItem.vue'
import { daEventCenter } from 'public/src/services/eventCenter'
import { useAppendageHandle } from './components/appendageHandle.js'
import { template } from '@shein/common-function'
import { getGiftProductList } from '../../utils/fetcher'
import Skeleton from './components/Skeleton.vue'

export default {
  name: 'BuyGiftModal',
  // 组件注册
  components: {
    ModalHeader,
    GearTab,
    AlertTips,
    PromotionProductItem,
    Skeleton
  },
  setup() {
    let store = useStore()
    const { 
      isShow,
      promotionId,
      setPromotionId,
      promotionItem,
      typeId,
      isLoaded,
      getAtomicInfo,
      difference,
      handleClose
    } = useAppendageHandle(store, 'buyGiftShow')
    return {
      isLoaded,
      promotionItem,
      typeId,
      isShow,
      promotionId,
      setPromotionId,
      getAtomicInfo,
      difference,
      handleClose
    }
  },
  data () {
    return {
      activeIndex: 0,
      noMoreData: false,
      page: 1,
      isFetching: false,
      isInited: false,
      gifts: [],
      firstLoad: true
    }
  },
  // 计算属性
  computed: {
    ...mapState([
      'language',
      'integrityPromotionInfo',
      'ABTParams', 
      'appendageDrawer'
    ]),
    tabs(){
      const { rules = [] } = this.promotionItem
      return [{
        id: 0,
        tabName: rules[0] ? template(rules[0].value, this.language.SHEIN_KEY_PC_28815) : '',
        satisfiedStatus: this.buyGiftsDisabled ? false : true
      }]
    },
    promotionAlertTips(){
      if(this.buyGiftsDisabled) {
        return this.difference(0, this.language.SHEIN_KEY_PC_28561)
      }
      return this.language.SHEIN_KEY_PC_28563
    },
    buyGiftsDisabled () {
      const { type, value, value_amount } = this.promotionItem.promotion_range_info?.diff || {}
      return !((type == 1 && value == 0) || (type != 1 && value_amount?.usdAmount == 0))
    },
    fulfillStatus () {
      // 买赠没有多档，只有满足和未满足
      return this.buyGiftsDisabled ? 0 : 1
    },
    // 投放id
    deliveryId() {
      return this.promotionItem.rules?.[0]?.delivery_id
    }
  },
  // 数据监听
  watch: {
    isShow(n) {
      if (n) {
        this.handleShow()
      }
    }
  },

  // 方法实现
  methods: {
    async handleShow () {
      if(this.promotionId != this.appendageDrawer.promotionId){
        this.setPromotionId()
        this.promotionId = this.appendageDrawer.promotionId
      }
      this.noMoreData = false
      await this.reFetch()
      this.firstLoad = false
      this.$nextTick(() => {
        if(this.appendageDrawer.fromType == 'addItem-auto'){
          this.$toast(this.language.SHEIN_KEY_PC_28557, 2500)
        }
        daEventCenter.triggerNotice({
          daId: '1-8-9-1',
          extraData: {
            promotion_typeid: this.typeId,
            promotion_code: this.promotionId,
            is_satisfied: this.buyGiftsDisabled ? 0 : 1,
            promotion_state: this.fulfillStatus,
            level: 1
          }
        })
      })
    },
    /**
     * 滚动到底
     */
    handleInfiniteScroll () {
      this.reFetch()
    },
    /**
     * 发送请求
     */
    async reFetch () {
      if (this.noMoreData) {
        return
      }
      if(this.isFetching){
        return
      }
      this.isFetching = true
      
      const handleListReady = list => {
        this.gifts = this.gifts.concat(list)
        
        this.isFetching = false
      }

      this.fetchGiftGoods()
        .then(async (list) => {
          handleListReady(list)
        })
        .catch(() => {
          this.isFetching = false
        })
    },
    async fetchGiftGoods(){
      const requestData = {
        promotion_id: this.promotionId,
        range: 1,
        limit: 20,
        page: this.page++,
        ...(this.deliveryId ? { delivery_id: this.deliveryId } : {})
      }
      try {
        let res = await getGiftProductList(requestData)
        if (res.code == 0 ) {
          const { list } = res.info || {}
          if (!list?.length) {
            this.noMoreData = true
          }
          return list
        } else {
          return []
        }
      } catch (e) {
        return []
      }
    },
    handleClosed() {
      this.reset()
    },
    reset() {
      this.gifts = []
      this.firstLoad = true
    }
  },
}
</script>

<style lang='less' scoped>
.cart-promotion__drawer{
  :deep(.sui-dialog__title-holder){
    padding: 0;
  }
  :deep(.sui-dialog__body){
    display: flex;
    flex-direction: column;
    padding: 0;
    min-height: 50vh;
    overflow: hidden;
  }
  .cart-promotion__tab-alert-wrapper{
    flex-shrink: 0;
    background: #fff;
    display: flex;
    justify-content: space-between;
    height: 44px;
    align-items: center;
    border-bottom: 1px solid #F1F1F1;
    padding: 0 48px;
  }
  .promotion-product__list{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    padding: 0 28px 0 48px;
    align-items: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
