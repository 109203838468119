<template>
  <span
    v-if="item.discount_price && item.discount_price.amount > 0"
    class="promotion-product__item-save-amount"
  >
    <span>
      <svg
        class="save-amount-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="9"
        viewBox="0 0 8 9"
        fill="none"
      >
        <path
          d="M2.5 1.86699C2.5 1.75654 2.58954 1.66699 2.7 1.66699H5.3C5.41046 1.66699 5.5 1.75654 5.5 1.86699V5.46699C5.5 5.57745 5.58954 5.66699 5.7 5.66699H6.51716C6.69534 5.66699 6.78457 5.88242 6.65858 6.00841L4.14142 8.52557C4.06332 8.60368 3.93668 8.60368 3.85858 8.52557L1.34142 6.00841C1.21543 5.88242 1.30466 5.66699 1.48284 5.66699H2.3C2.41046 5.66699 2.5 5.57745 2.5 5.46699V1.86699Z"
          fill="#FA6338"
        />
      </svg>
    </span>
    <span
      style="position: relative;top: -1px;"
    >
      {{ item.discount_price.amountWithSymbol }}
    </span>
  </span>
</template>

<script>

export default {
  name: 'SaveAmountTag',
  // 组件传参
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },

}
</script>

<style lang='less' scoped>
.promotion-product__item-save-amount{
  display: inline-flex;
  padding: 1px 2px;
  align-items: center;
  border-radius: 2px;
  border: 0.5px solid #FA6338;
  font-family: "SF UI Text";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #FA6338;
  height: 14px;
}
</style>
