<template>
  <div
    class="promotion-img"
  >
    <CropImageContainer
      :img-src="transformImg({ img: goodsImg })"
      fixed-ratio="3-4"
    />
  </div>
</template>

<script>
import { transformImg } from '@shein/common-function'
import { CropImageContainer } from 'public/src/pages/components/CropImageContainer/index.js'
import { getImageRatio } from 'public/src/pages/common/utils/index.js'

export default {
  name: 'PromotionImg',
  components: {
    CropImageContainer
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    goodsImg() {
      return this.item.goods_img
    }
  },
  methods: {
    transformImg,
    // 父组件调用
    async getImageRatio() {
      return await getImageRatio(this.goodsImg)
    }
  }
}
</script>

<style lang="less" scoped>
.promotion-img img {
  width: 100%;
}
</style>
