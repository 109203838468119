<template>
  <div
    class="cart-item-behavior"
    :style="styleConfig"
  >
    <img
      :src="info.icon"
      width="13"
      height="13"
    />
    <span class="label-text">
      {{ info.tagName }}
    </span>
  </div> 
</template>

<script>

export default {
  name: 'ActTag',
  props: {
    info: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    styleConfig() {
      return {
        '--bg-color': this.info.bgColor,
        '--tag-color': this.info.tagColor
      }
    }
  },
}
</script>

<style lang="less" scoped>
.cart-item-behavior {
  overflow: hidden;
  font-size: 12px;
  color: var(--tag-color);
  background-color: var(--bg-color);
  display: flex;
  align-items: center;
  .ico {
    /* stylelint-disable-next-line declaration-no-important */
    line-height: 0 !important;
  }
  .label-text {
    height: 14px;
    line-height: 14px;
    margin-left: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
