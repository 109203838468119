<template>
  <div
    class="header"
    :style="{
      '--sh-background-image': bgs.sh,
      '--rw-background-image': bgs.rw || bgs.sh,
    }"
  >
    <div class="title-content">
      <img
        src="http://img.ltwebstatic.com/images3_acp/2024/09/11/c4/172604568974193a6e6ad657b23089219a17c84c81.png" 
        alt="" 
        width="10" 
        height="21"
        :class="{
          'is-opacity': promotionType !== 'clubGift',
        }"
      />
      <span
        :class="[
          'title',
          {
            'title_club-gift': promotionType === 'clubGift'
          }
        ]"
      >
        {{ title }}
      </span>
      <img 
        src="http://img.ltwebstatic.com/images3_acp/2024/09/11/07/1726045676339d20b41b2c6f764d2a1e9b6443c8cf.png" 
        alt="" 
        width="10" 
        height="21"
        :class="{
          'is-opacity': promotionType !== 'clubGift',
        }"
      />
    </div>
    <div class="header-down-time">
      <count-down-time
        v-if="countDownTimeStamp"
        :time-stamp="countDownTimeStamp"
        show-type="inline-promotion"
        :show-end-tips="true"
        font-size="10px"
      />
    </div>
    <div class="close-icon">
      <Icon
        aria-hidden="true"
        name="sui_icon_closed_14px_1"
        size="14px"
        color="#fff"
        @click="handleClose"
      />
    </div>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue3'

export default {
  name: 'PromotionHeader',
  // 组件注册
  components: {
    Icon
  },
  // 组件传参
  props: {
    title: {
      type: String,
      default: ''
    },
    countDownTimeStamp: {
      type: [Number, String],
      default: 0
    },
    backgroundImages: {
      type: Object,
      default: () => ({})
    },
    promotionType: {
      type: String,
      default: ''
    }
  },
  computed: {
    bgs() {
      return {
        sh: 'url("/she_dist/images/cart/promotion/promotion_bg-d30ea133ad.png")',
        rw: 'url("/she_dist/rw_images/cart/promotion/promotion_bg.png")',
        ...(this.backgroundImages || {})
      }
    }
  },
  // 方法实现
  methods: {
    handleClose() {
      this.$emit('close')
    }
  },
  emits: ['close']
}
</script>

<style lang='less' scoped>
.header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 100px;
  position: relative;
  &::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: @zindex-zero;
    background-image: var(--sh-background-image);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transform: rotateY(0deg)/* rtl: rotateY(180deg) */;
    /* rw:begin */
    background-image: var(--rw-background-image);
  }
  .title-content {
    display: flex;
    align-items: center;
    justify-content: center;
    direction: ltr /* rtl: ignore */;
    z-index: @zindex-hack;
  }
  .title{
    height: 27px;
    line-height: 27px;
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    padding: 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: linear-gradient(180deg, #FFF 0%, #FFDE9F 100%);
    /* sh:begin */
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* sh:end */
    /* rw:begin */
    color: #FFF;
    text-shadow: 0px 2px 4px #D30056;
    background: transparent;
    &.title_club-gift{
      color: #FFF;
      background: none;
      background-clip: initial;
      -webkit-background-clip: initial;
      -webkit-text-fill-color: initial;
      text-shadow: 0 1px 2px rgba(192, 23, 0, 0.60);
    }
  }
  .close-icon{
    position: absolute;
    right: 4px;
    top: 4px;
    width: 24px;
    height: 24px;
    background: rgba(0,0,0, .3);
    z-index: @zindex-hack;
    line-height: 24px;
    cursor: pointer;
    fill: #fff;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .header-down-time{
    z-index: @zindex-hack;
    text-align: center;
  }
  :deep(.activity-tips){
    padding-right: 6px;
    color: #000;
    font-size: 11px;
    font-style: normal;
    font-weight: 510;
    line-height: normal;
  }
  :deep(.count-number){
    background: #000;
    min-width: 18px;
    height: 18px;
    font-size: 10px;
    font-style: normal;
    border-radius: 2px;
    display: inline-block;
    margin-right: 2px;
    padding: 0;
  }
  :deep(.time-content span){
    color: @sui_color_white;
  }
  :deep(.time-content){
    color: @sui_color_white;
  }
}
</style>
