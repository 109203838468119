<template>
  <div class="promotion-brand__wrapper">
    <div
      v-for="item in brands"
      :key="item.brand_code"
      v-expose="{
        id: '1-7-5-7',
        once: false,
        data: {
          promotionid: promotionId,
          brand_code: item.brand_code,
        }
      }"
      v-tap="{
        id: '1-7-5-8',
        data: {
          promotionid: promotionId,
          brand_code: item.brand_code,
          is_selected: selectedBrandCode == item.brand_code ? 1 : 0
        }
      }"
      :class="['promotion-brand__item', {'selected': selectedBrandCode === item.brand_code}]"
      @click="handleBrandClick(item.brand_code)"
    >
      {{ item.brand_name }}
      <i
        v-if="selectedBrandCode === item.brand_code"
        class="close-icon"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1024 1024"
        ><path
          d="M1024 0v1024L0 0h1024zM854.016 120.695 733.389 241.323 612.693 120.695 564.43 168.96 685.09 289.621 564.43 410.317l48.264 48.264 120.662-120.695L854.05 458.58l48.265-48.264L781.619 289.62 902.315 168.96l-48.265-48.265z"
          stroke="currentColor"
        /></svg>
      </i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BrandFilter',
  // 组件传参
  props: {
    selectedBrandCode: {
      type: String,
      default: ''
    },
    promotionId: {
      type: [String, Number],
      default: ''
    },
    brands: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  // 方法实现
  methods: {
    handleBrandClick(brandCode) {
      let targetBrandCode = this.selectedBrandCode === brandCode ? '' : brandCode
      this.$emit('change', targetBrandCode)
    },
  },
  emits: ['change'],
}
</script>

<style lang='less' scoped>
.promotion-brand__wrapper{
  flex-shrink: 0;
  padding: 16px 48px 0;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  .promotion-brand__item{
    // display: inline-block;
    padding: 4px 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    color: @sui_color_gray_dark3;
    border: 1px solid @sui_color_gray_light3;
    margin-right: 12px;
    cursor: pointer;
    position: relative;
    &.selected{
      border: 1px solid #222;
      color: #222;
    }
    .close-icon{
      width: 12px;
      position: absolute;
      right: 0;
      top: 0;
      transform: rotateY(0deg)/* rtl: rotateY(180deg) */;
    }
  }
}
</style>
