<template>
  <AddOnDialog
    v-if="addForCouponConfig.type === 'coupon'"
    v-model:visible="visible"
    v-bind="{
      ...addOnProps,
      onViewCart: onViewCart,
      onUpdateBusinessCart: onUpdateBusinessCart,
    }"
  />
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { sumBy } from 'lodash'
import AddOnDialog from '@/public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog.vue'
import { showAddOnDialog } from '@/public/src/pages/common/add-on-dialog'
import { handlerCouponSaveToastByBff } from '@/public/src/pages/cart_new/utils/couponSaveToast.js'

const { IS_RW } = gbCommonInfo
const { cartAbtInfo = {} } = window.gbCartSsrData || {}

export default {
  name: 'CartAddItem',
  components: {
    AddOnDialog,
  },
  data() {
    return {
      visible: false,
      unWatch: null,
    }
  },
  computed: {
    ...mapState([
      'language',
      'cartList',
      'cartInfo',
      'addForCouponConfig',
      'mallShippingInfo',
      'freeShippingAddOnType',
      'useBffApi',
    ]),
    ...mapGetters(['addOnPromotionList', 'cartAddTradeGoodsDetailSwitch']),
    originList() {
      return this.cartList.map((item) => item.product)
    },
    // 根据购物车内QS商品情况调整推荐策略
    requestParams() {
      if (IS_RW) return {}
      const limit = cartAbtInfo?.Quickshiprecommendthreshold?.param?.quickship_recommend_threshold || 0
      const productList = this.cartList.filter(item => Boolean(item.is_checked) && item.quick_ship == 1)
      const sum = sumBy(productList, item => +(item.feSalePrice?.usdAmount || 0))
      return { quickship_prior: sum > limit ? 1 : 0 }
    },
    addOnProps() {
      const { type, coupon, state, config } = this.addForCouponConfig
      const goodsIdMap = []
      const catIdMap = []
      this.originList.forEach((item) => {
        goodsIdMap.push(item.goods_id)
        catIdMap.push(item.cat_id)
      })
      if (type == 'coupon') {
        return {
          type,
          queryParams: {
            goodsIds: goodsIdMap, // 商品ID,
            cateIds: catIdMap, // 类目ID
            sceneId: 152, // 凑单列表值
            addOnType: 2,
          },
          saInfo: {
            promotion_code: coupon,
            state: state || '',
            activity_from: state || '',
            abtest: 'addnewhotJson'
          },
          config,
          coupon,
        }
      }
      return {}
    },
    addOnType() {
      let shipInfo = this.mallShippingInfo[this.freeShippingAddOnType] || {}
      if(shipInfo.isFirstFreeShipping) return 3
      return shipInfo.activity_type > 0 ? [ '', 0, 1, 4 ][shipInfo.activity_type] : ''
    },
  },
  watch: {
    'addForCouponConfig': {
      handler(v) {
        if (v.show) {
          if (v.type == 'AddOnV2') { // 凑单弹窗v2版本进行了重构, 走单独的分支
            this.showV2AddOnDialog(v.promotion)
            return
          }
          
          this.visible = true
        }
      },
      deep: true,
      immediate: true
    },
    visible(val) {
      if (!val) {
        this.updateAddForCoupon({ show: false, type: '' })
      }
    }
  },
  methods: {
    ...mapMutations(['updateAddForCoupon', 'changeAppendageDrawerStatus']),
    ...mapActions(['fetchCarts']),
    showV2AddOnDialog(promotion) {
      if (this.unWatch) {
        this.unWatch()
        this.unWatch = null
      }
      this.unWatch = this.$watch(() => {
        return this.addOnPromotionList.find((v) => v.promotion_id == promotion.promotion_id)
      }, (v) => {
        if (v) {
          const info = {
            ...v,
            ...this.getNewAddOnProps(v)
          }
          if (v.addOnType == 'accessory') {
            info.pickAddItemFn = (payload) => {
              if (payload?.appendageInfo) {
                this.changeAppendageDrawerStatus(payload.appendageInfo)
              }
            }
          }
          showAddOnDialog({
            ...info,
            onClose: () => {
              this.unWatch()
            },
            onUpdateBusinessCart: this.onUpdateBusinessCart,
            onViewTap: this.onViewTap,
          })
        }
      }, {
        deep: true,
        immediate: true,
      })
    },
    getNewAddOnProps(promotion) {
      const goodsIdMap = []
      const catIdMap = []
      this.originList.forEach((item) => {
        goodsIdMap.push(item.goods_id)
        catIdMap.push(item.cat_id)
      })
      return {
        queryParams: {
          goodsIds: goodsIdMap, // 商品ID,
          cateIds: catIdMap, // 类目ID
          sceneId: 152, // 凑单列表值
          addOnType: promotion?.addOnType == 'freeShipping' ? this.addOnType : 2,
          mallCode: promotion?.mallCode,
          goodsPrice: promotion?.goodsPrice, // 凑单差额价格(美分)
          includeTspId: promotion?.includeTspId,
          excludeTspId: promotion?.excludeTspId, 
          ...this.requestParams
        },
        saInfo: {
          promotion_code: promotion?.addOnType == 'freeShipping' ? promotion.activityId : promotion?.promotion_id,
          state: promotion?.addOnType == 'freeShipping' ? 'shipping_add' : 'promotion_add',
          activity_from: promotion?.addOnType == 'freeShipping' ? 'shipping_add' : 'promotion_add',
          abtest: 'addnewhotJson',
        },
        config: {
          isClickToDetail: this.cartAddTradeGoodsDetailSwitch,
        },
      }
    },
    onViewCart(data) {
      handlerCouponSaveToastByBff({
        language: this.language,
        cartAbtInfo,
        cartInfo: this.cartInfo,
        couponInfo: data?.couponInfo,
      }, {
        $toast: this.$toast,
      })
    },
    onUpdateBusinessCart() {
      this.fetchCarts()
    },
    onViewTap() {}
  }
}
</script>

<style lang="scss" scoped>
.coupon-promotion {
  margin-bottom: 16px;
}
</style>
