<script setup>
import { computed } from 'vue'
import { SLabel } from '@shein-aidc/sui-label'
import { Icon } from '@shein-aidc/icon-vue3'

const props = defineProps({
  info: {
    type: Object,
    default: () => ({})
  },
})
const isShowIcon = computed(() => {
  return props.info.style === '1'
})
const styleConfig = computed(() => {
  return {
    '--tag-bg-color': props.info.tag_bg_color,
    '--tag-text-color': props.info.tag_text_color,
  }
})

</script>

<template>
  <SLabel
    type="success"
    :class="[
      'quick-ship-tag',
      info.style && `quick-ship-tag_${info.style}`
    ]"
    :style="styleConfig"
  >
    <span class="quick-ship-tag__title">
      <Icon
        v-if="isShowIcon"
        name="sui_icon_qucikship_flat_14px"
        size="14px"
        class="quick-ship-tag__icon"
      />
      <span
        :class="[
          'quick-ship-tag__text',
          info.style && `quick-ship-tag__text_${info.style}`
        ]"
      >
        {{ info.tag_val_name_lang }}
      </span>
    </span>
    <template v-if="info?.tag_extra_val_name_lang">
      <span class="divider"></span>
      <span class="quick-ship-tag__tip">{{ info?.tag_extra_val_name_lang }}</span>
    </template>
  </SLabel>
</template>

<style lang="less" scoped>
.quick-ship-tag {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  background-color: var(--tag-bg-color);
  color: var(--tag-text-color);
  &.quick-ship-tag_1 {
    border-radius: 2px;
    box-shadow: 0 0 0 0.5px rgba(25, 128, 85, 0.20) inset;
  }
  .quick-ship-tag__title {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    max-width: 100%;
    .quick-ship-tag__icon {
      margin: 0 2px 0 0;
    }
    .quick-ship-tag__text {
      flex: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .quick-ship-tag__text_1 {
      padding: 0 1px 0 0;
      font-style: italic;
      font-weight: 590;
    }
  }
  .divider {
    flex-shrink: 0;
    display: inline-block;
    margin: 0 4px;
    width: 1px;
    height: 8px;
    background: rgba(25, 128, 85, 0.60);
  }
  .quick-ship-tag__tip {
    color: #198055;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    direction: rtl;
    unicode-bidi: embed;
  }
}
</style>
